import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import MenuMember from "../../../components/menu/MenuMember";

import Helper from "../../../services/helper";

import moment from "moment";

import img_logoBlack from "../../../assets/img/logo/logo-black.svg";

const Profile = () => {
  const stateProfile = useSelector((state) => state.profile);

  /* Navigate */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("You clicked", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Navigate */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageProfile">
        <main id="top">
          <div className="wrapContent">
            <section id="pMemberCard">
              <div className="bCard">
                <div className="bTitle">
                  <p>Welcome!</p>
                  <h2>{`${stateProfile.dataUser.name} ${stateProfile.dataUser.surname}`}</h2>
                </div>
                <div className="bPoint">
                  <img src={img_logoBlack} alt="" />
                  <p>Coming Soon!</p>
                </div>
              </div>
            </section>
            <section id="pProfileinfo">
              <div className="bInfo">
                <div className="bGroup">
                  <h2>
                    <span>
                      <i className="ico-personal"></i>Personal Information
                    </span>{" "}
                    <a
                      href="#"
                      className="btnEdit"
                      onClick={handleGotoPage}
                      data-page="edit-profile"
                    >
                      Edit<i></i>
                    </a>
                  </h2>
                  <div className="bRow">
                    <h3>Name</h3>
                    <p>{`${stateProfile.dataUser.name} ${stateProfile.dataUser.surname}`}</p>
                  </div>
                  <div className="bRow">
                    <h3>Mobile number</h3>
                    <p>{`${Helper.FORMAT.TEL(
                      stateProfile.dataUser.mobileNo
                    )}`}</p>
                  </div>
                  <div className="bRow">
                    <h3>Birthdate</h3>
                    {/* <p>{`${new Date(
                      stateProfile.dataUser.birthDate
                    ).toLocaleDateString("th-TH", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}`}</p> */}
                    <p>{moment(stateProfile.dataUser.birthDate).format("DD MMM YYYY")}</p>
                  </div>
                  <div className="bRow">
                    <h3>Gender</h3>
                    <p>{`${
                      stateProfile.dataUser.gender === "male"
                        ? "Male"
                        : stateProfile.dataUser.gender === "female"
                        ? "Female"
                        : stateProfile.dataUser.gender === "other"
                        ? "Other"
                        : ""
                    }`}</p>
                  </div>
                  <div className="bRow">
                    <h3>Company</h3>
                    <p>{`${stateProfile.dataUser.company}`}</p>
                  </div>
                  <div className="bRow">
                    <h3>Email</h3>
                    <p>{`${stateProfile.dataUser.companyEmail}`}</p>
                  </div>
                </div>
                <div className="bGroup">
                  <h2 className="tEvent">
                    <span>
                      <i className="ico-calendar"></i>Your Event
                    </span>{" "}
                  </h2>
                  <div className="bGroupEvent">
                    {/* <article>
                      <div className="bDate">
                        <div className="tDay">9</div>
                        <div className="tMonth">AUG</div>
                        {`<!-- data-status : new / full -->`}
                        <div className="tTag" data-status="new"></div>
                      </div>
                      <div className="bDetails">
                        <a
                          href="#"
                          onClick={handleGotoPage}
                          data-page="event-detail"
                        >
                          <h3>H+ Night</h3>
                          <h4>16.30 - 22.00</h4>
                          <p>Hikaru Stadium 17th FL.</p>
                        </a>
                      </div>
                    </article> */}
                    <article>
                      <div className="bDate">
                        <div className="tDay">8</div>
                        <div className="tMonth">NOV</div>
                        <div className="tTag" data-status="new"></div>
                      </div>
                      <div className="bDetails">
                        <a
                          href="#"
                          onClick={handleGotoPage}
                          data-page="event-detail"
                        >
                          <h3>H+ Game Night</h3>
                          <h4>17.00 - 21.00</h4>
                          <p>Hikaru Stadium 17th FL.</p>
                        </a>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </section>

            <MenuMember pageActive="profile" />

            {/* <!-- Modal -->
          <?php include 'inc/inc-modal.php';?> */}
          </div>
        </main>
      </div>
    </>
  );
};

export default Profile;
