import React from "react";
import { useNavigate } from "react-router-dom";

const MenuMember = (props) => {
  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  return (
    <>
      <section id="pBottomAction">
        <div className="bBottomAction">
          <div className="bBtn">
            <a
              href="#"
              className="btn"
              onClick={handleGotoPage}
              data-page="register"
            >
              Register
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default MenuMember;
