import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister, resetRegister } from "../../features/RegisterSlice";
import { setProfile } from "../../features/ProfileSlice";
import { PatternFormat } from "react-number-format";
import {
  chkMobileNo,
  chkCompanyEmail,
  createMember,
  company,
} from "../../services/Api/Module/Member";

import Helper, {
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../services/helper";

import PageHeader from "../../components/header/PageHeader";

const Register = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("register");

  const PageRoute = {
    home: "/",
    "register-complete": "/register-complete",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      birthDate: "",
      gender: "",
      mobileNo: "",
      company: "",
      department: "",
      companyEmail: "",
      isConsent: false,
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        // console.log(_value, _value.length);

        if (_value.length === 10) {
          handleChkMobileNoExists(_value);
        } else {
          _invalid = true;
        }
      }

      if (key === "companyEmail" && val !== "") {
        handleChkEmailExists(val);
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    const newData = {
      ...data,
      mobileNo: convertMobileFormat(data.mobileNo),
      lineUid: stateProfile.lineUid,
      // userAgent: navigator.userAgent,
    };

    // setDataRegister({ ...data, newData });

    // console.log("onSubmit >>", newData);
    handleCreateMember(newData);
  };

  const setDataRegister = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataRegister = () => {
    dispatch(resetRegister()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setDataProfile = (id = 0, newData = {}) => {
    const tmpObj = { id: id, dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };
  /* End Handle click submit form */

  /* onClick privacy policy save form data temp  */
  const setTempDataRegis = () => {
    const updateData = {
      name: watch("name"),
      surname: watch("surname"),
      birthDate: watch("birthDate"),
      gender: watch("gender"),
      mobileNo: convertMobileFormat(watch("mobileNo")),
      company: watch("company"),
      department: watch("department"),
      companyEmail: watch("companyEmail"),
      isConsent: watch("isConsent"),
    };
    // console.log("setTempDataRegis >>", updateData);
    setDataRegister(updateData);
  };
  /* End onClick privacy policy save form data temp */

  /* Check mobile nubmer exists real-time on server-side  */
  const handleChkMobileNoExists = (mobileNo) => {
    // console.log(mobileNo);

    clearErrors("mobileNo");

    getChkMobileNo({
      mobileNo: mobileNo,
    }).then((_rsData) => {
      // console.log(_rsData);
      // if (_rsData.status === true) {
      //
      // } else {
      //
      // }
    });
  };

  const getChkMobileNo = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await chkMobileNo({ params: payload });
      // console.log("getChkMobileNo data >>", data);

      if (data.resultCode === 40301) {
        setFormIsValid(false);

        setError(
          "mobileNo",
          {
            type: "manual",
            message: `เบอร์โทรศัพท์นี้มีผู้ใช้งานแล้ว`,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        clearErrors("mobileNo");
      }
    } catch (e) {
      console.log("ERR getChkMobileNo >> ", e);
    }

    return _return;
  };
  /* End Check mobile nubmer exists real-time on server-side  */

  /* Check company email exists real-time on server-side  */
  const handleChkEmailExists = (companyEmail) => {
    // console.log(companyEmail);

    clearErrors("companyEmail");

    getChkCompanyEmail({
      companyEmail: companyEmail,
    }).then((_rsData) => {
      // console.log(_rsData);
      // if (_rsData.status === true) {
      //
      // } else {
      //
      // }
    });
  };

  const getChkCompanyEmail = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await chkCompanyEmail({ params: payload });
      // console.log("getChkCompanyEmail data >>", data);

      if (data.resultCode === 40301) {
        setFormIsValid(false);

        setError(
          "companyEmail",
          {
            type: "manual",
            message: `อีเมลนี้มีผู้ใช้งานแล้ว`,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        clearErrors("companyEmail");
      }
    } catch (e) {
      console.log("ERR getChkCompanyEmail >> ", e);
    }

    return _return;
  };
  /* End Check company email exists real-time on server-side  */

  /* Insert Memeber  */
  const handleCreateMember = (data) => {
    // console.log(data);

    postCreateMember(data).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        if (typeof _rsData.result.id !== "undefined") {
          setDataProfile(_rsData.result.id, _rsData.result);
        }
      }
    });
  };

  const postCreateMember = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await createMember({ data: payload });
      // console.log("postCreateMember data >>", data);

      if (data.resultCode === 20201) {
        _return.status = true;
        _return.result = data.result;
      } else {
        let _title = `เกิดข้อผิดพลาด`,
          _subTitle = `กรุณาลองใหม่อีกครั้ง`;

        // modalAlert.info({
        //   open: true,
        //   title: _title,
        //   subTitle: _subTitle,
        //   content: "",
        // });
      }
    } catch (e) {
      console.log("ERR postCreateMember >> ", e);
    }

    return _return;
  };
  /* End Insert Memeber  */

  /* First event render */
  const [optionCompany, setOptionCompany] = useState([]);

  const getCompany = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await company({ params: payload });
      // console.log("getCompany data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.result;
        _return.token = data.token;
      }
    } catch (e) {
      console.log("ERR getCompany >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateRegister, stateProfile);

    getCompany({}).then((_rsData) => {
      // console.log("_rsData >>", _rsData);

      if (_rsData.status === true) {
        if (_rsData.result.length > 0) {
          setOptionCompany(_rsData.result);
        }
      }
    });

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      setValue("name", dataUser.name);
      setValue("surname", dataUser.surname);
      setValue("birthDate", dataUser.birthDate);
      setValue("gender", dataUser.gender);
      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
      setValue("company", dataUser.company);
      setValue("department", dataUser.department);
      setValue("companyEmail", dataUser.companyEmail);
      setValue("isConsent", dataUser.isConsent);

      let _chkFormIsValid = true;
      Object.entries(dataUser).forEach(([key, val]) => {
        if (val === "" || val === false || val === null) {
          _chkFormIsValid = false;
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("stateProfile >>", stateProfile);

    if (stateProfile.id !== 0) {
      resetDataRegister();

      navigate("/register-complete");
    }
  }, [stateProfile]);

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageRegister-form">
        <PageHeader title="Register" page="event-detail" />

        <main id="top">
          <div className="wrapContent">
            <section id="pRegister">
              <div className="bRegisterMain">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  onKeyDown={(e) => breakKeyDownEnter(e)}
                >
                  <div className="bForm">
                    <div className="bGroup">
                      <h2>
                        <i className="ico-personal"></i>Personal Information
                      </h2>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.name ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Name</div>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your name.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder={`Your name`}
                                onInput={Helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.name && (
                            <label htmlFor="" className="tError">
                              {errors.name.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.surname ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Surname</div>
                          <Controller
                            name="surname"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your surname.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder={`Your surname`}
                                onInput={Helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.surname && (
                            <label htmlFor="" className="tError">
                              {errors.surname.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.birthDate ? "error" : ""
                          }`}
                        >
                          <div className="tTitle-validate">Birthdate</div>
                          <Controller
                            name="birthDate"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your birthdate.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="date"
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.birthDate && (
                            <label htmlFor="" className="tError">
                              {errors.birthDate.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.gender ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Gender</div>
                          <Controller
                            name="gender"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please select your gender.`,
                            }}
                            render={({ field }) => (
                              <div className="gender">
                                <label className="control control--radio">
                                  Male
                                  <input
                                    {...field}
                                    type="radio"
                                    value="male"
                                    checked={field.value === "male"}
                                  />
                                  <div className="control__indicator"></div>
                                </label>
                                <label className="control control--radio">
                                  Female
                                  <input
                                    {...field}
                                    type="radio"
                                    value="female"
                                    checked={field.value === "female"}
                                  />
                                  <div className="control__indicator"></div>
                                </label>
                                <label className="control control--radio">
                                  Other
                                  <input
                                    {...field}
                                    type="radio"
                                    value="other"
                                    checked={field.value === "other"}
                                  />
                                  <div className="control__indicator"></div>
                                </label>
                              </div>
                            )}
                          />
                          {errors.gender && (
                            <label htmlFor="" className="tError">
                              {errors.gender.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group validate ${
                            errors.mobileNo ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Mobile number</div>
                          <Controller
                            name="mobileNo"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your mobile number.`,
                              pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                                message: `Please enter your mobile number.`,
                              },
                            }}
                            render={({ field: { name, value, onChange } }) => (
                              <PatternFormat
                                name={name}
                                value={value}
                                displayType="input"
                                format="###-###-####"
                                placeholder={`Your Mobile Number`}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.mobileNo && (
                            <label htmlFor="" className="tError">
                              {errors.mobileNo.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bGroup">
                      <h2>
                        <i className="ico-company"></i>Company Information
                      </h2>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.company ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Company</div>
                          <Controller
                            name="company"
                            control={control}
                            rules={{
                              required: `Please select company.`,
                            }}
                            defaultValue=""
                            render={({ field }) => (
                              <div className="select">
                                <select {...field}>
                                  <option value="" disabled>
                                    Your Company
                                  </option>
                                  {optionCompany.length > 0 &&
                                    optionCompany.map((item, idx) => (
                                      <option key={idx + 1} value={item.name}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            )}
                          />
                          {errors.company && (
                            <label htmlFor="" className="tError">
                              {errors.company.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.department ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Department</div>
                          <Controller
                            name="department"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your Department.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder={`Your Department`}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.department && (
                            <label htmlFor="" className="tError">
                              {errors.department.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.companyEmail ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Company Email</div>
                          <Controller
                            name="companyEmail"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your company email.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="email"
                                placeholder={`Your Company Email`}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.companyEmail && (
                            <label htmlFor="" className="tError">
                              {errors.companyEmail.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bPdpa">
                      <div className="bRow">
                        <div
                          className={`control-group pdpa ${
                            errors.isConsent ? "error" : ""
                          }`}
                        >
                          <label className="control control--checkbox">
                            <p>
                              {`I have read and accepted the `}
                              <Link
                                to="/privacy-policy"
                                onClick={() => {
                                  setTempDataRegis();
                                }}
                              >
                                Terms and conditions and the privacy policy.
                              </Link>
                            </p>
                            <Controller
                              name="isConsent"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: `จำเป็นต้องยินยอมรับข้อตกลงและเงื่อนไขและนโยบายความเป็นส่วนตัว`,
                              }}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="checkbox"
                                  checked={watch("isConsent")}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    handleCheckOnChange();
                                  }}
                                />
                              )}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          {errors.isConsent && (
                            <label htmlFor="" className="tError">
                              {errors.isConsent.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bBtn">
                      <button
                        className="btn"
                        type="submit"
                        disabled={formIsValid ? false : true}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Register;
