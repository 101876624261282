import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/header/PageHeader";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   console.log(t.lang);
  // }, []);

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pagePolicy">
        <PageHeader title="Privacy Policy" page="-1" />

        <main id="top">
          <div className="wrapContent">
            <section id="pPolicy">
              <div className="bPolicy">
                <div className="bContent">
                  <h2>1. Basic principles</h2>
                  <p>
                    Hakuhodo Inc. (“the Company", "We", "Us" or "Our") proposes,
                    produces and implements client marketing activities and
                    other measures designed to uplift lifestyle value based on
                    Our Sei-katsu-sha Insight and Partnership philosophies. We
                    believe that the most important consideration enabling Us to
                    consistently provide superior proposals is having deep
                    understanding of sei-katsu-sha, Our term for the holistic
                    person, and responding to their needs. As such,
                    sei-katsu-sha data, which is the basis of this
                    understanding, is crucial to Our corporate activities. For
                    this reason, until now We have recognized the management of
                    personal information, which is one kind of sei-katsu-sha
                    data, as an important matter in Our corporate activities,
                    and have acted accordingly. Given that social scrutiny of
                    the protection of personal information has been increasing
                    recently, We have expressly indicated Our policies regarding
                    the handling of personal information internally and
                    externally, and declare here that all executives and
                    employees will adhere to these policies.
                  </p>

                  <p>
                    The Company will handle personal information We acquire on
                    Our own, personal information entrusted to Us by business
                    partners, and personal information related to Our executives
                    and employees and those of Our affiliates appropriately and
                    in strict accordance with all related laws, industry
                    standards and internal regulations.
                  </p>

                  <h2>2. Strict adherence with related laws and regulations</h2>
                  <p>
                    The Company adheres to laws, regulations, guidelines set by
                    the government, and other norms related to personal
                    information.
                  </p>

                  <h2>3. Personal information handled by the Company</h2>
                  <p>
                    Except as prescribed by laws and regulations, the Company
                    utilizes personal information within the scope of the
                    utilization purposes outlined below. In cases where
                    utilization is for purposes other than those outlined below,
                    We will inform the individuals concerned of or disclose to
                    the public the utilization purpose at the time of
                    acquisition.
                  </p>

                  <p>
                    Moreover, in cases where a utilization purpose other than
                    those outlined below occurs or when acquiring personal
                    information of a type other than that outlined below, We
                    will inform of or disclose to the public such utilization
                    purpose in advance. In cases where necessary, We will
                    utilize the information only after obtaining consent.
                  </p>

                  <h3>
                    3-1. Personal information acquired by the Company itself
                  </h3>

                  <table>
                    <thead>
                      <tr>
                        <th>Type of personal information acquired</th>
                        <th>Utilization purpose</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Personal information related to participants of
                          seminars, lectures and other events organized by the
                          Company
                        </td>
                        <td>
                          <ul>
                            <li>
                              Emails regarding notification about the relevant
                              event to invite participation and regarding
                              sharing information, etc. after the event;
                              communicating and responding to inquiries by
                              telephone, etc.
                            </li>
                            <li>
                              Emails regarding notification about further
                              events, etc.; communicating and responding to
                              inquiries by telephone, etc.
                            </li>
                            <li>
                              Analysis work to improve events and the content of
                              email newsletters, etc.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information for supporting companies the
                          Company receives a third party provision of in
                          relation to events, etc. to which the Company is
                          providing support
                        </td>
                        <td>
                          <ul>
                            <li>
                              Providing information on and responding to
                              inquiries about the Company’s services, events,
                              seminars, campaigns, questionnaires, etc. by email
                              newsletter, etc.
                            </li>
                            <li>
                              Analysis work for selecting events to support and
                              for improving the content of email newsletters,
                              etc.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information related to subscription to the
                          Company's email newsletters
                        </td>
                        <td>
                          <ul>
                            <li>
                              Providing information on and responding to
                              inquiries about the Company’s services, events,
                              seminars, campaigns, questionnaires, etc. by email
                              newsletter, etc.
                            </li>
                            <li>
                              Analysis work to improve the content of email
                              newsletters, etc.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information related to individuals visiting
                          the Company
                        </td>
                        <td>
                          <ul>
                            <li>
                              Reception desk assistance and announcing the
                              arrival of a guest to the relevant Company
                              personnel
                            </li>
                            <li>
                              Crime prevention (including the use of security
                              cameras)
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information related to business partners
                        </td>
                        <td>
                          <ul>
                            <li>
                              Communication, etc. by email, telephone, etc.,
                              related to discussing and confirming business
                              matters
                            </li>
                            <li>
                              Completing shipping labels for sending voucher
                              copies of newspapers, magazines, etc.
                            </li>
                            <li>
                              Completing shipping labels for sending seasonal
                              greetings, etc.
                            </li>
                            <li>
                              Providing information on and responding to
                              inquiries about the Company’s services, events,
                              seminars, campaigns, questionnaires, etc., by
                              email newsletter, etc.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information related to individuals who would
                          like to join the Company and individuals who access
                          information provided by the Company
                        </td>
                        <td>
                          Communication related to recruitment activities and
                          joining the Company; utilization in activities
                          necessary for screening/future recruitment planning,
                          etc. of the Company
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information related to executives and
                          employees of the Company, and their families
                        </td>
                        <td>
                          <ul>
                            <li>
                              Executing work related to human resources
                              management (work management, reassignments,
                              secondments, transfers, promotions, retirements,
                              dismissals, evaluations, disciplinary actions,
                              communication in emergencies, etc.) and employment
                              management
                            </li>
                            <li>
                              Executing work related to wage management
                              (salaries, bonuses, retirement benefits, corporate
                              pensions, cost management, etc.)
                            </li>
                            <li>Executing work related to social insurance</li>
                            <li>
                              Executing work related to taxation (income taxes,
                              local inhabitant taxes, etc.)
                            </li>
                            <li>
                              Executing work related to implementing education
                            </li>
                            <li>
                              Executing work related to insurance and safety
                              management
                            </li>
                            <li>
                              Executing work related to welfare, occupational
                              health and safety, health management, etc.
                            </li>
                            <li>
                              Executing work related to building entry control
                              (creation of entry/exit cards, acquisition of
                              entry/exit logs, etc.)
                            </li>
                            <li>
                              Work, etc. related to or peripheral to the work
                              outlined above
                            </li>
                          </ul>
                          <br />
                          <i>
                            In cases where personal information will be utilized
                            for purposes other than those outlined above, the
                            utilization purpose will be indicated to executives
                            and employees as necessary
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information related to executives and
                          employees of Group companies, and their families
                        </td>
                        <td>
                          <p>
                            Executing work related to human resources management
                            (work management, reassignments, secondments,
                            transfers, promotions, retirements, dismissals,
                            evaluations, disciplinary actions, communication in
                            emergencies, etc.) and employment management
                          </p>
                          <br />
                          <ul>
                            <li>
                              Executing work related to wage management
                              (salaries, bonuses, retirement benefits, corporate
                              pensions, cost management, etc.)
                            </li>
                            <li>Executing work related to social insurance</li>
                            <li>
                              Executing work related to taxation (income taxes,
                              local inhabitant taxes, etc.)
                            </li>
                            <li>
                              Executing work related to implementing education
                            </li>
                            <li>
                              Executing work related to insurance and safety
                              management
                            </li>
                            <li>
                              Executing work related to welfare, occupational
                              health and safety, health management, etc.
                            </li>
                            <li>
                              Executing work related to building entry control
                              (creation of entry/exit cards, acquisition of
                              entry/exit logs, etc.)
                            </li>
                            <li>
                              Work, etc. related to or peripheral to the work
                              outlined above
                            </li>
                          </ul>
                          <br />
                          <i>
                            In cases where personal information will be utilized
                            for purposes other than those outlined above, the
                            utilization purpose will be indicated to executives
                            and employees as necessary
                          </i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information related to individuals who make
                          inquiries to the Company
                        </td>
                        <td>
                          <ul>
                            <li>
                              Communicating by email, telephone, etc. regarding
                              the inquiry
                            </li>
                            <li>
                              Confirming the identity of the individual making
                              the inquiry
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Personal information obtained from sei-katsu-sha
                          surveys, etc. conducted by the Company
                        </td>
                        <td>
                          <ul>
                            <li>
                              Communicating by email, telephone, etc. regarding
                              the relevant survey
                            </li>
                            <li>
                              Communicating by email, telephone, etc. regarding
                              further survey requests, etc.
                            </li>
                            <li>
                              Analysis and disclosure at seminars, etc. of the
                              Company of data that has been statistically
                              processed and digitalized so as to be
                              non-personally referable
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h3>
                    3-2. Personal information handled by the Company on
                    commission from business partners
                  </h3>

                  <table>
                    <thead>
                      <tr>
                        <th>
                          Type of personal information Utilization purpose
                          acquired
                        </th>
                        <th>Utilization purpose</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Personal information acquired, utilized, disposed of,
                          etc., by the Company on commission from a business
                          partner
                        </td>
                        <td>
                          Utilization necessary to execute all kinds of work on
                          commission (giveaway campaign work, event work, survey
                          and analysis work, etc.)
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h2>4. Providing personal information to third parties</h2>
                  <p>
                    The Company will not provide personal information to a third
                    party without obtaining the consent of the individuals
                    concerned in advance, except in the following cases that
                    meet legal requirements and descriptions:
                  </p>
                  <ul>
                    <li>cases based on laws and regulations</li>
                    <li>
                      cases in which there is a need to protect a human life,
                      body or fortune, and when it is difficult to obtain the
                      consent of the individuals concerned
                    </li>
                    <li>
                      cases in which there is a special need to enhance public
                      hygiene or promote fostering healthy children, and when it
                      is difficult to obtain the consent of the individuals
                      concerned
                    </li>
                    <li>
                      cases in which there is a need to cooperate in regard to a
                      central government organization or a local government, or
                      an individual entrusted by them performing affairs
                      prescribed by laws and regulations, and when there is a
                      possibility that obtaining a principal’s consent would
                      interfere with the performance of the said affairs
                    </li>
                    <li>
                      cases in which the individual concerned has explicitly
                      requested Us to disclose or provide the personal
                      information to a third party
                    </li>
                    <li>
                      cases in which a court of law, Public Prosecutor’s Office,
                      police, or other organization invested with similar
                      authority has requested Us to disclose personal
                      information in accordance with appropriate procedures
                    </li>
                    <li>
                      cases in which personal information is provided
                      accompanied with business succession caused by a merger or
                      other reason, and the information will be handled within
                      the scope of the pre-succession utilization purpose
                    </li>
                    <li>
                      cases in which personal information is provided or
                      disclosed to a business partner within the scope necessary
                      for achieving the utilization purpose
                    </li>
                  </ul>
                  <p>
                    In cases where personal information is provided to a third
                    party for reasons other than those outlined above, We will
                    inform the individuals concerned of the information to be
                    provided and the purpose of the provision, etc., and will
                    provide such information to the third party only with
                    consent.
                  </p>

                  <h2>5. Joint utilization</h2>
                  <p>
                    The Company will jointly utilize personal information within
                    the scope outlined below. Moreover, when jointly utilizing
                    personal information of a type other than those outlined
                    below, We will utilize it only after informing of or
                    disclosing to the public such joint utilization in advance.
                  </p>

                  <h3>Case of joint utilization :</h3>
                  <p>
                    Cases in which joint utilization with Hakuhodo DY Holdings
                    Group companies is necessary to respond appropriatel y to an
                    inquiry to the Company
                    <br />
                    <br />
                    <strong>Information to be jointly utilized :</strong>
                    <br />
                    Names, addresses, telephone numbers, email addresses, places
                    of work, inquiry content
                    <br />
                    <br />
                    <strong>Entity that will jointly utilize it :</strong>
                    <br />
                    Hakuhodo DY Holdings Group companies https://www.hakuho
                    dody-holdings.co.jp/english/group/about/ group.html Includes
                    Hakuhodo DY Holdings Group companies not shown on the linked
                    website
                    <br />
                    <br />
                    <strong>Utilization purpose :</strong>
                    <br />
                    Communicat ing with the relevant individual, sending
                    materials, etc. and other necessary procedures related to
                    the inquiry content
                    <br />
                    <br />
                    <strong>Personal information administrator:</strong>
                    Masayuki Mizushima, President & CEO, Hakuhodo Inc. Akasaka
                    Biz Tower, 5-3-1 Akasaka, Minato-ku, Tokyo 107-6322
                  </p>
                  <h3>Case of joint utilization :</h3>
                  <p>
                    Cases when conveying information from Hakuhodo DY Holdings
                    Group companies
                    <br />
                    <br />
                    <strong>Information to be jointly utilized :</strong>
                    <br />
                    Personal information about participants in seminars,
                    lectures and other events organized by the Company;
                    subscribers to the Company’s email newsletters; personal
                    information for supporting companies the Company receives a
                    third party provision of in relation to events, etc. to
                    which the Company is providing support (company names,
                    division names, job titles, names, email addresses,
                    telephone numbers, addresses)
                    <br />
                    <br />
                    <strong>Entity that will jointly utilize it :</strong>
                    <br />
                    Hakuhodo DY Holdings Group companies
                    https://www.hakuhodody-holdings.co.jp/english/group/about/group.html
                    Includes Hakuhodo DY Holdings Group companies not shown on
                    the linked website
                    <br />
                    <br />
                    <strong>Utilization purpose :</strong>
                    <br />
                    <ul>
                      <li>
                        Providing information about the Company’s services,
                        events, seminars, campaigns, questionnair es, etc. by
                        email newsletter, etc.
                      </li>
                      <li>
                        Analysis work for improving the content of email
                        newsletters, etc.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <strong>Personal information administrator:</strong>
                    Masayuki Mizushima, President & CEO, Hakuhodo Inc. Akasaka
                    Biz Tower, 5-3-1 Akasaka, Minato-ku, Tokyo 107-6322
                  </p>

                  <h2>6. Handling of personal information related to minors</h2>
                  <p>
                    The Company will not acquire or utilize personal information
                    of minors without the consent of a guardian. If by any
                    chance you discover a minor has provided personal
                    information to Us without the consent of a guardian, you are
                    requested to contact Us immediately.
                  </p>

                  <h2>7. Security control actions</h2>
                  <p>
                    The Company takes the following actions for the security
                    control of personal information entrusted to Us.
                  </p>
                  <ul>
                    <li>
                      <strong>
                        <u>Clarification of information handling</u>
                      </strong>
                      <br />
                      <br />
                      We have set out regulations for the secure and appropriate
                      handling of personal information, including access, usage,
                      storage, disposal and return of personal information, and
                      adhere to these regulations under the supervision of an
                      administrator.
                      <br />
                      <br />
                      Moreover, acquired personal information will be erased or
                      disposed of appropriately once the legal period for its
                      storage or the period necessary for executing work has
                      passed.
                    </li>
                    <li>
                      <strong>
                        <u>
                          Confirmation of appropriateness of personal
                          information entrusted to Us by business partners and
                          the appropriate utilization and security control
                          thereof
                        </u>
                      </strong>
                      <br />
                      <br />
                      In regard to personal information entrusted to Us by
                      business partners in the course of executing Our business,
                      We will confirm the method of acquisition and the scope of
                      consented utilization of such personal information and
                      utilize it appropriately in line with the relevant
                      business partner’s requests and Our internal regulations.
                    </li>
                    <li>
                      <strong>
                        <u>Executive and employee education</u>
                      </strong>
                      <br />
                      <br />
                      Education and awareness activities for executives and
                      employees to instill understanding of the importance of
                      personal information protection, legal requirements,
                      control systems, handling regulations and measures in case
                      of violation of these regulations are implemented
                      regularly (including annual implementation of e-learning
                      on information security, including personal information
                      for all executives and employees) to promote the
                      appropriate handling of personal information and
                      compliance with relevant regulations and rules.
                    </li>
                    <li>
                      <strong>
                        <u>
                          Establishment of information handling control systems
                        </u>
                      </strong>
                      <br />
                      <br />
                      To protect personal information, We have clarified
                      internal control and responsibility systems and selected
                      an administrator responsible for personal information.
                    </li>
                    <li>
                      <strong>
                        <u>Measures for unauthorized access</u>
                      </strong>
                      <br />
                      <br />
                      To prevent theft, loss, falsification or destruction of
                      personal information due to unauthorized access, We have
                      established information systems and information control
                      systems, restrict access to information systems and areas
                      where personal information is handled, and appropriately
                      build mechanisms to protect against unauthorized external
                      access and against unauthorized software.
                    </li>
                    <li>
                      <strong>
                        <u>
                          Ensuring information security in cases where
                          information processing is outsourced
                        </u>
                      </strong>
                      <br />
                      <br />
                      In cases where the processing of personal information is
                      outsourced to an external party, We will, in accordance
                      with Our internal regulations, select outsourcing partners
                      that have been confirmed will ensure the security control
                      of information, and exercise appropriate supervision.
                    </li>
                    <li>
                      <strong>
                        <u>
                          Establishment of immediate response systems for when a
                          problem occurs
                        </u>
                      </strong>
                      <br />
                      <br />
                      If by any chance a problem should occur, We will assess
                      the situation promptly and accurately and establish an
                      organization to make appropriate decisions and take
                      appropriate action.
                    </li>
                    <li>
                      <strong>
                        <u>Continuous improvement</u>
                      </strong>
                      <br />
                      <br />
                      We make ongoing efforts to improve through internal
                      auditing of information security by the Auditing Division
                      and otherwise monitoring the control status, and by
                      responding to changes in the external environment.
                    </li>
                    <li>
                      <strong>
                        <u>Assessment of the external environment</u>
                      </strong>
                      <br />
                      <br />
                      When storing, transferring, etc. personal information
                      entrusted to Us in or to another country, We will comply
                      with relevant laws and regulations regarding personal
                      information and undertake security control after
                      understanding systems regarding the protection of personal
                      information in the relevant country.
                    </li>
                  </ul>

                  <h2>
                    8.Disclosure, correction, deletion, etc. of personal
                    information
                  </h2>
                  <p>
                    In such case as you desire any of the following related to
                    personal information the Company has acquired from you,
                    please inquire using the inquiry form linked in 9. Contact
                    point for inquiries.
                  </p>
                  <ul>
                    <li>
                      Requesting disclosure, correction, addition, deletion or
                      suspension of utilization; requesting cessation of
                      utilization of personal information or cessation of
                      provision to a third party due to withdrawal of consent
                    </li>
                    <li>
                      Making a complaint, etc., regarding handling of personal
                      information
                    </li>
                  </ul>

                  <h2>9.Contact point for inquiries</h2>
                  <p>
                    For inquiries related to this Privacy Policy, please contact
                  </p>
                  <p>
                    Email:{" "}
                    <a href="mailto:lalita.po@hakuhodo-international.co.th">
                      <i>
                        <u>lalita.po@hakuhodo-international.co.th</u>
                      </i>
                    </a>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};
export default PrivacyPolicy;
