import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import img_complete from "../../assets/img/ci/complete/img-complete.svg";

const RegisterComplete = () => {
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("You clicked", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister, stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageRegister-complete">
        <main id="top">
          <div className="wrapContent">
            <section id="pComplete">
              <div className="bComplete">
                <div className="bPic">
                  <img src={img_complete} alt="" />
                </div>
                <div className="bBtn">
                  <a
                    href="#"
                    className="btn white"
                    onClick={handleGotoPage}
                    data-page="profile"
                  >
                    Get started <i></i>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default RegisterComplete;
