import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLiff } from "react-liff";

import { checkInEvent } from "../../services/Api/Module/Member";

import img_checkIn from "../../assets/img/ci/complete/img-checkin.svg";

const EventCheckIn = () => {
  // const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  let { eventId } = useParams();

  let { liff } = useLiff();

  /* Handle goto page */
  const navigate = useNavigate();

  // const goBack = () => {
  //   if (liff.isInClient()) {
  //     liff.closeWindow();
  //   } else {
  //     navigate("/");
  //   }
  // };
  /* End Handle goto page */

  /* First event render */
  const handleCheckInEvent = (data) => {
    // console.log(data);

    postCheckInEvent(data).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        setTimeout(function () {
          navigate(`/event/check-in/complete/${data.eventId}`);
        }, 1000);
      } else {
        console.warn(_rsData);
      }
    });
  };

  const postCheckInEvent = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await checkInEvent({ data: payload });
      // console.log("postCheckInEvent data >>", data);

      if (data.resultCode === 20201 || data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.result;
      } else {
        // let _title = `เกิดข้อผิดพลาด`,
        //   _subTitle = `กรุณาลองใหม่อีกครั้ง`;

        // modalAlert.info({
        //   open: true,
        //   title: _title,
        //   subTitle: _subTitle,
        //   content: "",
        // });
      }
    } catch (e) {
      console.log("ERR postCheckInEvent >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile, eventId);

    handleCheckInEvent({
      eventId: parseInt(eventId),
      memberId: parseInt(stateProfile.id),
      // userAgent: navigator.userAgent,
    });
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageRegister-complete">
        <main id="top">
          <div className="wrapContent">
            <section id="pComplete">
              <div className="bComplete">
                <div className="bPic">
                  <img src={img_checkIn} alt="" />
                </div>
                {/* <div className="bBtn">
                  <button
                    className="btn white"
                    onClick={goBack}
                    data-page="profile"
                  >
                    Close <i></i>
                  </button>
                </div> */}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default EventCheckIn;
