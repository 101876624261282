import React from "react";
import { useNavigate } from "react-router-dom";

const PageHeader = (props) => {
  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    if (page === "-1") {
      navigate(-1);
    } else {
      navigate("/" + page);
    }
  };
  /* End Handle goto page */

  return (
    <>
      <section id="pPageHeader">
        <div className="bHeader">
          <a
            className="btnIconBack"
            onClick={handleGotoPage}
            data-page={props.page}
          ></a>
          <h1 className="bTitle">{props.title}</h1>
        </div>
      </section>
    </>
  );
};

export default PageHeader;
