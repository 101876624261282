import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import RegisterPage from "./pages/register/index";
import RegisterCompletePage from "./pages/register/registerComplete";

import ProfilePage from "./pages/account/profile/index";
import EditProfilePage from "./pages/account/profile/editProfile";

import EventDetailPage from "./pages/event/eventDetail";
import EventCheckinPage from "./pages/event/checkIn";
import EventCheckinCompletePage from "./pages/event/checkInComplete";

import ServicePage from "./pages/service/index";

import PrivacyPolicyPage from "./pages/consent/privacyPolicy";

const appRouter = (props) => {
  // console.log(props.isHasProfile, props.profileData);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <RegisterPage />
            }
          />
          <Route
            path="/home"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <RegisterPage />
            }
          />
          <Route
            path="/register"
            element={
              props.isHasProfile ? <Navigate to="/profile" /> : <RegisterPage />
            }
          />
          <Route path="/register-complete" element={<RegisterCompletePage />} />

          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/edit-profile" element={<EditProfilePage />} />

          <Route path='/event-detail' element={<Navigate to='/event/detail' />} />
          <Route path="/event/detail" element={<EventDetailPage />} />
          <Route
            path="/event/check-in/:eventId"
            element={<EventCheckinPage />}
          />
          <Route
            path="/event/check-in/complete/:eventId"
            element={<EventCheckinCompletePage />}
          />

          <Route path="/service" element={<ServicePage />} />
          {/* <Route
            path="/service"
            element={
              !props.isHasProfile ? (
                <Navigate to="/register" />
              ) : (
                <ServicePage />
              )
            }
          /> */}

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          {/* Not found 404 */}
          <Route
            path="*"
            exact={true}
            // element={<Navigate replace to="/404" />}
            element={<Navigate replace to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default appRouter;
