import React from "react";
import { useNavigate } from "react-router-dom";

const MenuMember = (props) => {
  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  return (
    <>
      <section id="pBottomMenu">
        <div className="bBottomMenu">
          <nav>
            <a
              href="#"
              className={`menuProfile ${
                props.pageActive === "profile" ? "active" : ""
              }`}
              onClick={handleGotoPage}
              data-page="profile"
            >
              <i></i>
              <span>Profile</span>
            </a>
            {/* <!-- <a href="coupon-all.php" class="menuCoupon <?php echo $menuCoupon;?>"><i></i><span>Coupon</span></a> --> */}
            <a
              href="#"
              className={`menuService ${
                props.pageActive === "service" ? "active" : ""
              }`}
              onClick={handleGotoPage}
              data-page="service"
            >
              <i></i>
              <span>Service</span>
            </a>
          </nav>
        </div>
      </section>
    </>
  );
};

export default MenuMember;
