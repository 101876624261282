import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../features/ProfileSlice";
import { PatternFormat } from "react-number-format";
import {
  chkMobileNo,
  chkCompanyEmail,
  updateMember,
  company,
} from "../../../services/Api/Module/Member";

import Helper, {
  // convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

import moment from "moment";

import PageHeader from "../../../components/header/PageHeader";
import ModalEditProfileSuccess from "../../../components/modal/account/profile/ModalEditProfileSuccess";

const EditProfile = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      birthDate: "",
      gender: "",
      mobileNo: "",
      company: "",
      department: "",
      companyEmail: "",
      isConsent: false,
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = async () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        // console.log(_value, _value.length);

        if (_value.length === 10) {
          // console.log(stateProfile.dataUser.mobileNo, _value);

          clearErrors("mobileNo");

          if (_value !== stateProfile.dataUser.mobileNo) {
            // console.log("handleChkMobileNoExists", await handleChkMobileNoExists(_value));
            _invalid = await handleChkMobileNoExists(_value);
          }
        } else {
          _invalid = true;
        }
      }

      if (key === "companyEmail" && val !== "") {
        if (val !== stateProfile.dataUser.companyEmail) {
          handleChkEmailExists(val);
        }
      }

      if (key === "isConsent") {
        let _value = val;
        // console.log(_value);

        if (_value || !_value) {
          _invalid = false;
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidFields, _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Check mobile nubmer exists real-time on server-side  */
  const handleChkMobileNoExists = async (mobileNo) => {
    // console.log(mobileNo);

    let _return = false;

    await getChkMobileNo({
      mobileNo: mobileNo,
      id: stateProfile.id,
    }).then((_rsData) => {
      console.log(_rsData);

      if (_rsData.status === true) {
        setError(
          "mobileNo",
          {
            type: "manual",
            message: `เบอร์โทรศัพท์นี้มีผู้ใช้งานแล้ว`,
          },
          {
            shouldFocus: true,
          }
        );

        _return = true;
      }
    });

    return _return;
  };

  const getChkMobileNo = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await chkMobileNo({ params: payload });
      if (data.resultCode === 40301) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR getChkMobileNo >> ", e);
    }

    return _return;
  };
  /* End Check mobile nubmer exists real-time on server-side  */

  /* Check company email exists real-time on server-side  */
  const handleChkEmailExists = (companyEmail) => {
    // console.log(companyEmail);

    clearErrors("companyEmail");

    getChkCompanyEmail({
      companyEmail: companyEmail,
    }).then((_rsData) => {
      // console.log(_rsData);
      // if (_rsData.status === true) {
      //
      // } else {
      //
      // }
    });
  };

  const getChkCompanyEmail = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await chkCompanyEmail({ params: payload });
      // console.log("getChkCompanyEmail data >>", data);

      if (data.resultCode === 40301) {
        setFormIsValid(false);

        setError(
          "companyEmail",
          {
            type: "manual",
            message: `อีเมลนี้มีผู้ใช้งานแล้ว`,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        clearErrors("mobileNo");
      }
    } catch (e) {
      console.log("ERR getChkCompanyEmail >> ", e);
    }

    return _return;
  };
  /* End Check company email exists real-time on server-side  */

  /* Modal edit profile success */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCloseModal = () => {
    setIsModalShow(false);

    setTimeout(() => navigate("/profile"), 200);
  };
  /* End Modal edit profile success */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    data.mobileNo = convertMobileFormat(data.mobileNo);
    // console.log(data.mobileNo, (data.mobileNo !== stateProfile.dataUser.mobileNo));

    let payload = {
      id: stateProfile.id,
      lineUid: stateProfile.lineUid,
      ...data,
    };
    // console.log(payload);

    postUpdateMember(payload).then((_rsDataUpdateCustomer) => {
      // console.log(_rsDataUpdateCustomer);

      if (_rsDataUpdateCustomer.status === true) {
        setDataProfile({
          ...stateProfile.dataUser,
          ...data,
        });

        setIsModalShow(true);
      }
    });
  };

  const postUpdateMember = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateMember({ data: payload });
      // console.log("postUpdateMember data >>", data);
      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.result;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR postUpdateMember >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  const [optionCompany, setOptionCompany] = useState([]);

  const getCompany = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await company({ params: payload });
      // console.log("getCompany data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.result;
        _return.token = data.token;
      }
    } catch (e) {
      console.log("ERR getCompany >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (
      stateProfile.id === 0 ||
      Object.keys(stateProfile.dataUser).length === 0
    ) {
      navigate("/");
    }

    getCompany({}).then((_rsData) => {
      // console.log("_rsData >>", _rsData);

      if (_rsData.status === true) {
        if (_rsData.result.length > 0) {
          setOptionCompany(_rsData.result);
        }
      }
    });

    const { dataUser } = stateProfile;

    if (Object.keys(dataUser).length !== 0) {
      setValue("name", dataUser.name);
      setValue("surname", dataUser.surname);
      setValue("birthDate", moment(dataUser.birthDate).format("YYYY-MM-DD"));
      setValue("gender", dataUser.gender);
      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
      setValue("company", dataUser.company);
      setValue("department", dataUser.department);
      setValue("companyEmail", dataUser.companyEmail);
      setValue("isConsent", dataUser.isConsent, { shouldValidate: false });

      let _chkFormIsValid = true;
      const _chkField = ["isConsent"];
      Object.entries(dataUser).forEach(([key, val]) => {
        if (!_chkField.includes(key)) {
          if (val === "" || val === false || val === null) {
            _chkFormIsValid = false;
          }
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log(stateProfile);
  }, [stateProfile]);

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageProfile-edit">
        <PageHeader title="Edit Profile" page="profile" />

        <main id="top">
          <div className="wrapContent">
            <section id="pRegister">
              <div className="bRegisterMain">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  onKeyDown={(e) => breakKeyDownEnter(e)}
                >
                  <div className="bForm">
                    <div className="bGroup">
                      <h2>
                        <i className="ico-personal"></i>Personal Information
                      </h2>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.name ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Name</div>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your name.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder={`Your name`}
                                onInput={Helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.name && (
                            <label htmlFor="" className="tError">
                              {errors.name.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.surname ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Surname</div>
                          <Controller
                            name="surname"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your surname.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder={`Your surname`}
                                onInput={Helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.surname && (
                            <label htmlFor="" className="tError">
                              {errors.surname.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.birthDate ? "error" : ""
                          }`}
                        >
                          <div className="tTitle-validate">Birthdate</div>
                          <Controller
                            name="birthDate"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your birthdate.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="date"
                                placeholder={`Your surname`}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.birthDate && (
                            <label htmlFor="" className="tError">
                              {errors.birthDate.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.gender ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Gender</div>
                          <Controller
                            name="gender"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please select your gender.`,
                            }}
                            render={({ field }) => (
                              <div className="gender">
                                <label className="control control--radio">
                                  Male
                                  <input
                                    {...field}
                                    type="radio"
                                    value="male"
                                    checked={field.value === "male"}
                                  />
                                  <div className="control__indicator"></div>
                                </label>
                                <label className="control control--radio">
                                  Female
                                  <input
                                    {...field}
                                    type="radio"
                                    value="female"
                                    checked={field.value === "female"}
                                  />
                                  <div className="control__indicator"></div>
                                </label>
                                <label className="control control--radio">
                                  Other
                                  <input
                                    {...field}
                                    type="radio"
                                    value="other"
                                    checked={field.value === "other"}
                                  />
                                  <div className="control__indicator"></div>
                                </label>
                              </div>
                            )}
                          />
                          {errors.gender && (
                            <label htmlFor="" className="tError">
                              {errors.gender.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.mobileNo ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Mobile number</div>
                          <Controller
                            name="mobileNo"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your mobile number.`,
                              pattern: {
                                value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                                message: `Please enter your mobile number.`,
                              },
                            }}
                            render={({ field: { name, value, onChange } }) => (
                              <PatternFormat
                                name={name}
                                value={value}
                                displayType="input"
                                format="###-###-####"
                                placeholder={`Your Mobile Number`}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.mobileNo && (
                            <label htmlFor="" className="tError">
                              {errors.mobileNo.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bGroup">
                      <h2>
                        <i className="ico-company"></i>Company Information
                      </h2>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.company ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Company</div>
                          <Controller
                            name="company"
                            control={control}
                            rules={{
                              required: `Please select company.`,
                            }}
                            render={({ field }) => (
                              <div className="select">
                                <select {...field}>
                                  {optionCompany.length > 0 &&
                                    optionCompany.map((item, idx) => (
                                      <option key={idx + 1} value={item.name}>
                                        {item.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            )}
                          />
                          {errors.company && (
                            <label htmlFor="" className="tError">
                              {errors.company.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.department ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Department</div>
                          <Controller
                            name="department"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your Department.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder={`Your Department`}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.department && (
                            <label htmlFor="" className="tError">
                              {errors.department.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${
                            errors.companyEmail ? "error" : ""
                          }`}
                        >
                          <div className="tTitle">Company Email</div>
                          <Controller
                            name="companyEmail"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `Please enter your company email.`,
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="email"
                                placeholder={`Your Company Email`}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          {errors.companyEmail && (
                            <label htmlFor="" className="tError">
                              {errors.companyEmail.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bPdpa">
                      <div className="bRow">
                        <div
                          className={`control-group pdpa ${
                            errors.isConsent ? "error" : ""
                          }`}
                        >
                          <label className="control control--checkbox">
                            <p>
                              {`I have read and accepted the `}
                              <Link to="/privacy-policy">
                                Terms and conditions and the privacy policy.
                              </Link>
                            </p>
                            <Controller
                              name="isConsent"
                              control={control}
                              defaultValue=""
                              // rules={{
                              //   required: `จำเป็นต้องยินยอมรับข้อตกลงและเงื่อนไขและนโยบายความเป็นส่วนตัว`,
                              // }}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="checkbox"
                                  checked={watch("isConsent")}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    handleCheckOnChange();
                                  }}
                                />
                              )}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          {errors.isConsent && (
                            <label htmlFor="" className="tError">
                              {errors.isConsent.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bBtn">
                      <button
                        className="btn"
                        type="submit"
                        disabled={formIsValid ? false : true}
                      >
                        Confirm
                      </button>
                      <button
                        className="btn outline"
                        onClick={handleGotoPage}
                        data-page="profile"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>

            <ModalEditProfileSuccess
              open={isModalShow}
              onClose={handleCloseModal}
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default EditProfile;
