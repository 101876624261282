import { get, post, put } from "../Config";

export function OtpRequest({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_request`, data, timeOut: 20000 });
}
export function OtpVerify({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_verify`, data, timeOut: 20000 });
}
/* ######################################################################## */

export function searchMember({ params }) {
  if (typeof params.q !== "undefined") {
    if (typeof params.lineUid !== "undefined") {
      return get({ url: `/api/sb/v1/members/${params.q}/${params.lineUid}` });
    }
  }
}

export function chkMobileNo({ params }) {
  return get({ url: `/api/sb/v1/members/chkMobileNo/${params.mobileNo}` });
}

export function chkCompanyEmail({ params }) {
  return get({
    url: `/api/sb/v1/members/chkCompanyEmail/${params.companyEmail}`,
  });
}

export function createMember({ data }) {
  return post({ url: `/api/sb/v1/members`, data, timeOut: 20000 });
}

export function updateMember({ data }) {
  const _id = data.id;
  delete data.id;
  delete data.lineUid;

  return put({ url: `/api/sb/v1/members/${_id}`, data, timeOut: 20000 });
}

export function company({ params }) {
  return get({ url: `/api/sb/v1/company` });
}

export function checkInEvent({ data }) {
  return post({ url: `api/sb/v1/event/check-in`, data, timeOut: 20000 });
}
