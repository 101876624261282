import React from "react";
import { Helmet } from "react-helmet";

import img_logo from "../../assets/img/logo/logo.svg";

const PageSplash = () => {
  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageSplash">
        <main id="top">
          <div className="wrapContent">
            <section id="pLogo">
              <img src={img_logo} alt="" />
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default PageSplash;
