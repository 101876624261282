import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLiff } from "react-liff";

import img_checkInComplete from "../../assets/img/ci/complete/img-checkin-complete.svg";

const EventCheckInComplete = () => {
  // const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  let { eventId } = useParams();

  let { liff } = useLiff();

  /* Navigate */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("You clicked", e.currentTarget.dataset.page);

    if (liff.isInClient()) {
      liff.closeWindow();
    } else {
      const page = e.currentTarget.dataset.page;
      navigate("/" + page);
    }
  };
  /* End Navigate */

  /* First event render */
  // useEffect(() => {
  //   // console.log("useEffect !", stateProfile, eventId);
  // }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageRegister-complete">
        <main id="top">
          <div className="wrapContent">
            <section id="pComplete">
              <div className="bComplete">
                <div className="bPic">
                  <img src={img_checkInComplete} alt="" />
                </div>
                <div className="bBtn">
                  {liff.isInClient() ? (
                    <button className="btn white" onClick={handleGotoPage}>
                      Close
                    </button>
                  ) : (
                    <button
                      className="btn white"
                      onClick={handleGotoPage}
                      data-page="profile"
                    >
                      Profile
                    </button>
                  )}
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default EventCheckInComplete;
