import React from "react";
import { Helmet } from "react-helmet";

import PageHeader from "../../components/header/PageHeader";
import MenuAction from "../../components/menu/MenuAction";

// import img_picArtwork1 from "../../assets/img/ci/event/event1/pic-artwork-1.jpg";
// import img_picArtwork2 from "../../assets/img/ci/event/event1/pic-artwork-2.jpg";
import img_picArtwork08nov from "../../assets/img/ci/event/event1/pic-artwork-08nov.jpg";

const EventDetail = () => {
  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageEvent-details">
        <PageHeader title="Save the dates" page="profile" />

        <main id="top">
          <div className="wrapContent">
            <section id="pEventDetails">
              <div className="bEventMain">
                <div className="bPic">
                  {/* <img
                    src={img_picArtwork1}
                    alt=""
                  />
                  <img
                    src={img_picArtwork2}
                    alt=""
                  /> */}
                  <img
                    src={img_picArtwork08nov}
                    alt=""
                  />
                </div>
              </div>
            </section>

            <MenuAction />
          </div>
        </main>
      </div>
    </>
  );
};

export default EventDetail;
