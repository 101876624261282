import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/header/PageHeader";

import img_picArtwork from "../../assets/img/ci/service/pic-artwork.svg";

const ServicePage = () => {
  /* Navigate */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("You clicked", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Navigate */

  return (
    <>
      <Helmet>
        <title>H+ Club</title>
        <meta name="title" content="H+ Club" />
        <meta name="description" content="H+ Club" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="H+ Club" />
        <meta property="og:description" content="H+ Club" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="H+ Club" />
      </Helmet>

      <div id="pageService">
        <PageHeader title="Services" page="-1" />

        <main id="top">
          <div className="wrapContent">
            <section id="pService">
              <div className="bService">
                <div className="bPic">
                  <img src={img_picArtwork} />
                </div>
                <div className="bBtn">
                  <a
                    href="#"
                    className="btn white"
                    onClick={handleGotoPage}
                    data-page="register"
                  >
                    Register
                  </a>
                </div>
              </div>
            </section>

            {/* <!-- Page Button Menu --> */}
            {/* <?php $menuService = "active"; include 'inc/inc-bottom-menu.php';?> */}

            {/* <!-- Modal --> */}
            {/* <?php include 'inc/inc-modal.php';?> */}
          </div>
        </main>
      </div>
    </>
  );
};

export default ServicePage;
